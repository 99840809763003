/*
 * @Date: 2023-12-18 10:24:25
 * @Author: 大年
 * @Version: 1.0
 * @Contact: 353036652@qq.com
 */
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/index/index.vue"),
  },
  {
    path: "/apps",
    name: "Apps",
    component: () => import("@/views/app/index.vue"),
  },
  {
    path: "/app/:guid",
    name: "AppDetails",
    component: () => import("@/views/app/detail.vue"),
  },
  {
    path: "/user-comment",
    name: "UserComment",
    component: () => import("@/views/app/comment.vue"),
  },
  {
    path: "/all-comment",
    name: "AllComment",
    component: () => import("@/views/app/commentAll.vue"),
  },
  {
    path: "/comment-detail",
    name: "CommentDetail",
    component: () => import("@/views/app/commentDetail.vue"),
  },
  {
    path: "/post",
    name: "Post",
    component: () => import("@/views/post/index.vue"),
  },
  {
    path: "/post/:guid",
    name: "PostDetail",
    component: () => import("@/views/post/detail.vue"),
  },
  {
    path: "/gpts",
    name: "Gpts",
    component: () => import("@/views/gpts/index.vue"),
  },
  {
    path: "/gpts/:guid",
    name: "GptsDetail",
    component: () => import("@/views/gpts/detail.vue"),
  },
  {
    path: "/mine",
    name: "Mine",
    component: () => import("@/views/mine/index.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/mine/settings/index"),
  },
  {
    path: "/nickname",
    name: "Nickname",
    component: () => import("@/views/mine/settings/nickname"),
  },
  {
    path: "/password",
    name: "Password",
    component: () => import("@/views/mine/settings/password"),
  },
  {
    path: "/bindmobile",
    name: "BindMobile",
    component: () => import("@/views/mine/settings/bindmobile"),
  },
  {
    path: "/menber",
    name: "Menber",
    component: () => import("@/views/mine/menber/index"),
  },
  {
    path: "/integral",
    name: "Integral",
    component: () => import("@/views/mine/bill/integral"),
  },
  {
    path: "/recharge",
    name: "Recharge",
    component: () => import("@/views/mine/bill/recharge"),
  },
  {
    path: "/revenue-log",
    name: "RevenueLog",
    component: () => import("@/views/mine/revenueLog/index"),
  },
  {
    path: "/sso/login",
    name: "Login",
    component: () => import("@/views/login/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    return {
      left: 0,
      top: 0,
    };
  },
});

export default router;
